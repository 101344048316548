<template>
  <v-row>
    <v-col cols="12" sm="6" md="3">
      <v-text-field
        v-model="params.title"
        :label="$t('filters.name')"
        prepend-inner-icon="search"
        clearable
        single-line
        @input="handleDebounce"
      />
    </v-col>

    <v-col cols="12" sm="6" md="9" class="d-flex align-center justify-end">
      <BaseFilterDialogAndChips
        :submitted-values="submittedValues"
        @open="copyFilterParams"
        @clear:filter="clearFilter"
        @reset="resetFilters"
        @submit="submitFilters"
      >
        <v-row dense>
          <v-col v-if="$isAdmin()" cols="12" sm="6">
            <ContactAutocomplete
              v-model="params.client_id"
              @update:initial-item="updateAutocompleteValue('client_id', $event)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="params.status"
              :items="projectStatuses"
              :label="$t('general.status')"
              clearable
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="params.estimated_beginning_at_from"
              :label="$t('projects.general.starts_from')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="params.estimated_beginning_at_to"
              :label="$t('projects.general.starts_to')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="params.deadline_from"
              :label="$t('projects.general.ends_from')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="params.deadline_to"
              :label="$t('projects.general.ends_to')"
            />
          </v-col>
        </v-row>
      </BaseFilterDialogAndChips>
    </v-col>
  </v-row>
</template>

<script>
import BaseFilterDialogAndChips from '@/components/base/BaseFilterDialogAndChips';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete';
import filterMixin from '@/mixins/filter-mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectFilter',

  components: { ContactAutocomplete, BaseDatepickerInput, BaseFilterDialogAndChips },

  mixins: [filterMixin],

  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters('projects', ['projectStatuses']),

    submittedValues() {
      const values = [];
      const {
        client_id,
        status,
        estimated_beginning_at_from,
        estimated_beginning_at_to,
        deadline_from,
        deadline_to,
      } = this.filterParams;

      if (client_id && this.autocompleteValues.client_id?.id === client_id) {
        values.push({
          key: 'client_id',
          value: this.autocompleteValues.client_id.name,
          label: this.$t('general.contact'),
        });
      }

      if (status) {
        values.push({
          key: 'status',
          value: this.$t(`projects.project_status.${status}`),
          label: this.$t('general.status'),
        });
      }

      if (estimated_beginning_at_from) {
        values.push({
          key: 'estimated_beginning_at_from',
          value: estimated_beginning_at_from,
          label: this.$t('projects.general.starts_from'),
        });
      }

      if (estimated_beginning_at_to) {
        values.push({
          key: 'estimated_beginning_at_to',
          value: estimated_beginning_at_to,
          label: this.$t('projects.general.starts_to'),
        });
      }

      if (deadline_from) {
        values.push({
          key: 'deadline_from',
          value: deadline_from,
          label: this.$t('projects.general.ends_from'),
        });
      }

      if (deadline_to) {
        values.push({
          key: 'deadline_to',
          value: deadline_to,
          label: this.$t('projects.general.ends_to'),
        });
      }

      return values;
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchProjects(projectFilterParams)">{{ $t('general.projects') }}</v-tab>

      <v-tab-item>
        <div class="d-flex align-center justify-end px-4">
          <ProjectFilter
            :filter-params="projectFilterParams"
            @change="$router.push({ name: 'projects', query: $event })"
            @reset="resetFilters"
          />
        </div>

        <ProjectTable
          :items="projects"
          :loading="$store.getters.loading['get:api/projects']"
          :pagination="projectPagination"
          @click:row="viewProject"
          @edit="$router.push({ name: 'editProject', params: { projectId: $event.id } })"
          @delete="deleteProject"
          @update:page="onPageChange"
        />
      </v-tab-item>
    </v-tabs>

    <BasePrimaryActionButton
      v-if="$can(['projects.create.*']) && !$isClient()"
      @click="$router.push({ name: 'createProject' })"
    />

    <router-view />
  </v-container>
</template>

<script>
import ProjectFilter from '@/components/filters/ProjectFilter';
import ProjectTable from '@/components/tables/ProjectTable';
import { mapActions, mapState } from 'vuex';
import { getDefaultProjectFilterParams } from '@/store/modules/projects-module';
import { getSanitizedFilterParams } from '@/util/filter-params';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';

export default {
  name: 'Projects',

  components: { BasePrimaryActionButton, ProjectTable, ProjectFilter },

  computed: {
    ...mapState('projects', ['projects', 'projectPagination', 'projectFilterParams']),
  },

  created() {
    this.fetchProjects(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.projectFilterParams) !== JSON.stringify(params) &&
      to.name === 'projects'
    ) {
      this.fetchProjects(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('projects', ['fetchProjects', 'deleteProject']),

    viewProject(project) {
      this.$router.push({ name: 'project', params: { projectId: project.id } });
    },

    onPageChange(page) {
      const query = { ...this.projectFilterParams, page };
      if (page === 1) {
        delete query.page;
      }
      this.$router.push({ name: 'projectsList', query });
    },

    resetFilters() {
      this.$router.push({
        name: 'projectsList',
        query: getDefaultProjectFilterParams(),
      });
    },
  },
};
</script>

<style scoped></style>
